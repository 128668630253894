<template>
  <v-app id="inspire">

<template v-if='this.getPkh === ""'>
    <LandingPage />
  </template>

  <template v-else>

    <v-toolbar v-if="isMobile" density="compact" color="grey-darken-4">
      <v-app-bar-nav-icon @click.stop="drawerMainNav = !drawerMainNav"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ updateToolbarTitle() }}</v-toolbar-title>

      <v-spacer></v-spacer>

     
    </v-toolbar>
    

    <!-- @click.stop="rail = !rail"   :rail="rail" -->
   


    <v-navigation-drawer app v-model="drawerMainNav" class="bg-black">
      <v-list>


      <!--   Connect Wallet -->
        <v-list-item @click.stop="drawerMainNav = !drawerMainNav" :prepend-avatar="activeUserAvatar" :title="formatTez(getPkh)"
          subtitle="Connected">
        



        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list density="compact">
        <!-- @click.stop="rail = !rail" -->
        <v-list-item prepend-icon="mdi-home" title="Home" value="Home" link to="/"></v-list-item>
        
       
    <v-list v-model:opened="openSubMenu">
   
    

        <v-list-group value="Frames">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Your Frames"
              prepend-icon="mdi-wallpaper"
            ></v-list-item>
          </template>

          <v-list>


<v-list-item v-for="(item, index) in getActiveFrame" :key="index" link :to="'/frame/' + item.id">

  <v-list-item-title class="ml-6">{{ item.id }}</v-list-item-title>


  <template v-slot:prepend>
    <v-icon icon="mdi-chevron-right"></v-icon>
  </template>



  <!-- <v-chip size="x-small" label class="mr-6">{{ item.id }}</v-chip> -->

  <!-- SPÄTER: {{ item.frame.title            }} -->
  <!-- LATER amount of pieces in it 
  <template v-slot:append>
<v-badge class="grey">4</v-badge>
</template>
-->
</v-list-item>
</v-list>

        </v-list-group>


        <v-list-group value="Collection">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Your Collection"
              prepend-icon="mdi-view-dashboard-outline"
            ></v-list-item>
          </template>


          <v-list>
          <v-list-item v-for="(item, i) in itemsCollection" :key="i" :value="item" link :to="item.to"
            @click="getAllTokensByPlatform(getPkh, item.platform)">
            <!--
            <template v-slot:prepend>

              <v-img
              class="mr-8"
  width="25"
  src="@/assets/re-tain.svg"
></v-img>

            </template>
   -->

   <!-- LATER-->
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>


            <v-list-item-title v-text="item.text"></v-list-item-title>

            <!--
            <template v-slot:append>
          <v-btn
            color="grey-lighten-1"
            icon="mdi-information"
            variant="text"
          ></v-btn>
        </template>
        -->

          </v-list-item>
        </v-list>

        
        </v-list-group>
    </v-list>


    <!-- IF DESKTOP 
        
        <v-list-item prepend-icon="mdi-wallpaper" title="Your Frames" value="Your Frames" link @click="goToFirstFrame()"></v-list-item>




        <v-list-item prepend-icon="mdi-view-dashboard-outline" title="Your Collection" value="Your Collection" link
          to="/collection/objkt"></v-list-item>
-->

      </v-list>

      <v-divider></v-divider>
      <v-list density="compact">
        <v-list-item prepend-icon="mdi-gift-outline" title="Donate" value="Donate"
          @click="dialogDonation = true"></v-list-item>
      </v-list>
     
      <template v-slot:append>
          <div class="pa-2">
            <v-btn depressed variant="plain" @click="disconnectWallet" >
              Logout
            </v-btn>
          </div>
        </template>
    
    </v-navigation-drawer>


  


    <v-main>

      <template v-if="this.$route.path.indexOf('/frame') !== -1">
<!-- 


        <div>
          <form autocomplete="off" @submit.prevent="handleFormSubmit" class="flex-row">
            <div>
              <input required minlength="3" name="frame" class="form-input" type="text" placeholder="New frame..."
                v-model="frame" />
            </div>
            <button :class="loading && 'loading'">
              LOADING...
            </button>
          </form>
          <small class="frame-input-count">{{ frame.length }}/200</small>
        </div>
-->

        <!-- 
        <div class="title-wrapper">
          <input required minlength="3" name="title" class="form-input" type="text" placeholder="Frame title..."
            v-model="new_title" maxlength="50" />
          <small class="title-input-count">{{ new_title.length }}/50</small>
        </div>

     


          TODO HEADLINE SPÄTER HIER OF FRAME


to.diyframe.xyz/23
          https://alpha.diyframe.xyz/frame/tz1Yx6cVjG8KF3Q1a96GDx9QeSdKJ18C88HN/23

http://localhost:8080/frames
        -->



        <v-toolbar v-if="serverItemsFrame.length > 0" color="#212121" >
          <!-- to.diyframe.xyz/4 getPkh -->
          <v-btn color="#FF5400" variant="outlined"  :href="openFrameURL(this.$route.params.id)" target="_blank"
            outlined>
            <v-icon color="#FF5400" start>mdi-play-circle-outline</v-icon> Play Frame
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn class="text-grey" @click="deleteFrameID(this.$route.params.id)" outlined>
            <v-icon color="#FF5400" start>mdi-trash-can-outline</v-icon> Delete Frame
          </v-btn>

          <!-- you have unsaved changes SPÄTER REIN: damit man alles aufeinmal speichern kann
          <v-btn @click="handleFrameUpdate('',this.$route.params.id)" outlined>
            <v-icon start>mdi-content-save</v-icon> + SAVE TO CHAIN +
          </v-btn>
-->

          <!--    
    OPTIONS
  
  item.selectable.token_id = delete whole frame 


-->

          <!-- 
            TODO COPY LINK / DELETE FRAME / SHARE



      <v-btn outlined>
    <v-icon start>mdi-playlist-edit</v-icon>    Edit 
      </v-btn>
    -->

          <!--
          <v-spacer></v-spacer>

          <v-responsive class="mx-auto" max-width="344">
            <v-text-field hide-details prepend-inner-icon="mdi-magnify" single-line v-model="search" label="Search"
              placeholder="Title or artist name..." filled dense clearable></v-text-field>



          </v-responsive>

           -->



        </v-toolbar>

        <!--:items= serverItemsFrame     v-if="serverItemsFrame.length > 0" 
        
        v-if="this.$route.params.id" 

        OPTI:
        -->





        <template v-if="serverItemsFrame.length >= 0">


        <v-data-table-server v-model:items-per-page="itemsPerPageFrame" :search="searchFrame" :headers="headersFrame"
          :items-length="totalItemsFrame" :items="serverItemsFrame" :loading="loadingFrame" class="elevation-1" item-value="name"
          @update:options="loadFrame(false, this.$route.params.id)">

          <!--   lazy-src="@/assets/lazy-src.png" -->

          <!-- GO FILTER BY FRAME ID -->

          <template #[`item.thumb`]="{ item }">


            <v-img width="100" contain :src="IPFStoHTTPbutiq(item.selectable)" alt=":)">
              <!--
<template v-slot:placeholder>
<div class="d-flex align-center justify-center fill-height">
<v-progress-circular
color="grey-lighten-4"
indeterminate
></v-progress-circular>
</div>
</template>
-->
            </v-img>
          </template>


          <template  #[`item.name`]="{ item }">

            <span class="d-inline-block text-truncate" style="max-width: 100px;">
            {{ item.selectable.name }}
        </span>
          </template>


          <template #[`item.artist`]="{ item }">


            <!-- 
TODO LATER: artist profile image + vllt verified?!

<v-avatar class="mr-1">
<v-img

:src="IPFStoHTTPbutiq(item.selectable.token)"
alt=":)"
>

<template v-slot:placeholder>
<div class="d-flex align-center justify-center fill-height">
<v-progress-circular
color="grey-lighten-4"
indeterminate
></v-progress-circular>
</div>
</template>
</v-img>
</v-avatar>

-->

<span class="text-truncate" >
              {{ formatTez(item.selectable.artist_address) }}
              <!--     {{ getUsername(item.selectable.token.artist_profile.alias, item.selectable.token.artist_address) }} -->
            </span>

          </template>



          <template #[`item.platform`]="{ item }">



            <v-chip>
              {{ item.selectable.platform }}
            </v-chip>

          
          </template>

          <template #[`item.action`]="{ item }">
            <!--
                 <v-btn @click="toggleUpdateModal" flat class="mr-3" density="comfortable" icon="mdi-pencil"></v-btn>

          <v-btn @click="deleteFrameID(item.id)"  flat class="mr-3" density="comfortable" icon="mdi-eye-outline"></v-btn>
        variant="outlined"
        -->

            <!-- item.selectable.token_id = delete whole frame -->
            <!-- DIYFRAME ID von aus frame, FALSCH -->

            <v-btn  @click="deleteFrameEntry(item.selectable.artifact_uri, this.$route.params.id)" flat class="mr-3" density="comfortable"
             >  <v-icon color="#FF5400" start>mdi-trash-can-outline</v-icon></v-btn>

      

          </template>


        </v-data-table-server>
      </template> 
        <template v-else>
          <v-container>
          <v-row>

            <v-col cols="12" md="12">
              


                <v-card class="mx-auto justify-center" max-width="500" color="transparent">
                  <v-card-item>
                    <div>
                      <div class="text-overline mb-1">
                        <!-- or it's not validated on chain yet -->
                        You Don't Have Any Frames Yet
                      </div>
                      <div class="text-h6 mb-1">
                        How to create a diyFrame
                      </div>
                      <div class="text-headline">
                        1. Navigate to [collection]<br/>
                        2. Click the [+] icon to add an artwork<br/>
                        3. Choose [add to a new frame] in the dialog<br/>
                        4. Open your [Tezos wallet] and approve the transaction to securely store your diyFrame on-chain</div>
                    </div>


                <!-- 
                    <iframe  ref="frame1" width="560" height="215" src="https://www.youtube.com/embed/MY5SatbZMAo?si=9XCfeLPiWlJq7rN9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
-->
                  </v-card-item>

                  <v-card-actions>
                 

                    <v-btn width="330" color="#FF5400" variant="flat" @click="openSubMenu = ['Collection']" to="/collection/objkt">Go to your Collection</v-btn>



                  </v-card-actions>
                </v-card>

            </v-col>


          </v-row>
        </v-container>

        </template>

   
 





        <div v-if="getLoading" class="loader">
          LOADING
        </div>


<!-- 

        <div class="frame-wrapper" v-for="(frame, index) in getActiveFrame" :key="index">

          {{ frame.frame }}
         


          <div @click="toggleModal" role="button">


         

            <br />
            <br />
            <br />

          </div>
          <div class="row">
            <small> {{ time }} </small>
            <button @click="deleteFrame(22)">delete</button>
            <button @click="toggleUpdateModal">update</button>
            <button @click="toggle">)markdone</button>
          </div>

          <frame-modal v-if="showModal" :toggleModal="toggleModal" :frame="frame" :time="time" :done="done"
            :id="id"></frame-modal>

          <updateFrame v-if="updateModal" :frame="frame" :id="id" :toggleModal="toggleUpdateModal" />
        </div>
-->






        <v-card flat v-if="loadingWriteToContract">
          Writing updates to contract...
          <v-progress-linear :active="loadingWriteToContract" :indeterminate="loadingWriteToContract" absolute bottom
            color="deep-purple-accent-4"></v-progress-linear>
        </v-card>

      </template>



      <template v-if="this.$route.path === '/'">

        <v-container>
          <v-row>

            <v-col cols="12" md="12">
              


                <v-card class="mx-auto justify-center" max-width="500" color="transparent">
                  <v-card-item>
                    <div>
                      <div class="text-overline mb-1">
                        GET STARTED
                      </div>
                      <div class="text-h6 mb-1">
                        How to create a diyFrame
                      </div>
                      <div class="text-headline">
                        1. Navigate to [collection]<br/>
                        2. Click the [+] icon to add an artwork<br/>
                        3. Choose [add to a new frame] in the dialog<br/>
                        4. Open your [Tezos wallet] and approve the transaction to securely store your diyFrame on-chain</div>
                    </div>


                <!-- 
                    <iframe  ref="frame1" width="560" height="215" src="https://www.youtube.com/embed/MY5SatbZMAo?si=9XCfeLPiWlJq7rN9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
-->
                  </v-card-item>

                  <v-card-actions>
                 

                    <v-btn width="330" color="#FF5400" variant="flat" @click="openSubMenu = ['Collection']"  to="/collection/objkt">Go to your Collection</v-btn>
                 <!--    Connect Wallet -->

                  </v-card-actions>
                </v-card>

            </v-col>


          </v-row>
        </v-container>



      </template>



      <template v-if="this.$route.path.indexOf('/collection') !== -1">



        <v-data-table-server v-model:items-per-page="itemsPerPageCollection" :search="searchCollection" :headers="headersCollection"
          :items-length="totalItemsCollection" :items="serverItemsCollection" :loading="loadingCollection" class="elevation-1" item-value="name"
          @update:options="loadCollection">

          <!--   lazy-src="@/assets/lazy-src.png" -->

          <!-- 
          <template #[`item.action`]>

          
                   <v-icon v-if="item.disabled" small color="success">
                       mdi-check-circle
                     </v-icon>

            <v-icon @click="dialogAddToFrame = true">
              mdi-checkbox-blank-outline
            </v-icon>
-->
          <!--            
   <v-btn @click="deleteFrameID(10)" flat class="mr-3" density="comfortable" icon="mdi-pencil"></v-btn>

            <v-btn flat class="mr-3" density="comfortable" icon="mdi-eye-outline"></v-btn>
-->

          <!-- add to select frame frame @click="" 
            <v-btn flat class="mr-3" density="comfortable" icon="mdi-plus-circle"></v-btn>
          
          </template>

   -->
          <template #[`item.thumb`]="{ item }">

            <v-img width="100" contain :src="IPFStoHTTPbutiq(item.selectable.token)" alt=":)">
              <!--
<template v-slot:placeholder>
  <div class="d-flex align-center justify-center fill-height">
    <v-progress-circular
      color="grey-lighten-4"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
-->
            </v-img>
          </template>

          <template #[`item.name`]="{ item }">
            
            <span class="d-inline-block text-truncate" style="max-width: 100px;">
              {{ item.selectable.token.name }}
            </span>
          </template>

          <template #[`item.artist`]="{ item }">


            <!-- 
TODO LATER: artist profile image + vllt verified?!

<v-avatar class="mr-1">
<v-img

:src="IPFStoHTTPbutiq(item.selectable.token)"
alt=":)"
>

<template v-slot:placeholder>
<div class="d-flex align-center justify-center fill-height">
<v-progress-circular
color="grey-lighten-4"
indeterminate
></v-progress-circular>
</div>
</template>
</v-img>
</v-avatar>

-->

            <span class="text-truncate" v-if="item.selectable.token">
              {{ formatTez(item.selectable.token.artist_address) }}
              <!--     {{ getUsername(item.selectable.token.artist_profile.alias, item.selectable.token.artist_address) }} -->
            </span>
          </template>

          <template #[`item.platform`]="{ item }">

            <v-chip>
              {{ item.selectable.token.platform }}
            </v-chip>
          </template>


          <template #[`item.action`]="{ item }">
            <!--            
   <v-btn @click="deleteFrameID(10)" flat class="mr-3" density="comfortable" icon="mdi-pencil"></v-btn>

            <v-btn flat class="mr-3" density="comfortable" icon="mdi-eye-outline"></v-btn>
-->

            <!-- add to select frame frame @click="" TODO add payload  , addFrame(item) -->
            <v-btn  flat class="mr-3" @click="dialogAddToFrame = true, this.addToFrameTemp = '', this.addToFrameTemp = item.selectable.token" density="comfortable"

              > <v-icon  color="#FF5400">mdi-plus-circle</v-icon> </v-btn>

          </template>

        </v-data-table-server>



      </template>




    </v-main>


    <v-navigation-drawer v-model="drawerAd" location="right">


      <!--
      <v-list>
    
        <v-list-item
          
        >   (Later rein  Piece details)</v-list-item>

        <v-list-item
          
          >   (Ads space )</v-list-item>

      </v-list>

    -->
      <v-list-item nav>
        <template v-slot:append>
          <!-- @click="dialogDonation = true" -->
          <v-btn variant="text" icon="mdi-close" @click="drawerAd = false"></v-btn>
        </template>
      </v-list-item>

      <!-- TODO random AD re-tain, artery, follow andre, follow re-tain, donate -->
      <v-card-item>
        <div>
          <div class="text-overline mb-2">
            AD



          </div>

          <v-img src="https://blog.re-tain.xyz/Re%E2%80%94tain_Logo_Color-F0FF42.svg" width="100px" class="mb-2"></v-img>



          <div class="mb-1">
            New Generative Art Platform on Tezos
          </div>
          <div class="text-caption">Re—tain lets you deploy generative art on your own Tezos smart contract.</div>
        </div>
      </v-card-item>




      <!-- https://re-tain.xyz?utm_source=diyframe-ad&utm_medium=banner&utm_campaign=df&utm_id=diyframe -->
      <v-card-actions>
        <v-btn href="https://re-tain.xyz/?ref=diyframe.xyz" target="_blank" block variant="outlined">
          re-tain.xyz
        </v-btn>
      </v-card-actions>


      <template v-slot:append>

              <v-btn class="mb-2" size="x-small" plain block  flat  href="mailto:andre@diyframe.xyz?subject=Advertise" target="_blank" 
           > Advertise here
          </v-btn>
    
      </template>


      <!-- 
TODO
- Follow DIYFRAME
insta
twitter
linkedin?

- FOLLOW ANDRE
insta
twitter
linkedin

! - Delegate to Artery inline

    -->

      <!-- 
    <v-list-item
    class="mt-8"
          prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
          title="John Leider"
          nav
        >
      </v-list-item>
      -->


      <!-- 
  <v-card
  variant="outlined"
    class="mx-auto"
    max-width="344"
  >
    <v-img
      src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
      height="200px"
      cover
    ></v-img>

    <v-card-title>
      Top western road trips
    </v-card-title>

    <v-card-subtitle>
      1,000 miles of wonder
    </v-card-subtitle>

    <v-card-actions>
      <v-btn variant="outlined">
        Button
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
-->

      <!--
<template v-slot:append>
  
      

    
  <v-card
    class="mx-auto"
    max-width="344"
    variant="outlined"
  >
    <v-card-item>
      <div>
        <div class="text-overline mb-1">
          OVERLINE
        </div>
        <div class="text-h6 mb-1">
          Support
        </div>
        <div class="text-caption">2023, Andre Fuchs</div>
      </div>
    </v-card-item>

    <v-card-actions>
      <v-btn variant="outlined">
        Button
      </v-btn>
    </v-card-actions>
  </v-card>

        </template>
-->
    </v-navigation-drawer>



    <!-- 
    <v-footer
      app
      height="72"
    >
FOOTER
    </v-footer>
    -->



    <v-row justify="center">
      <v-dialog v-model="dialogLanding" fullscreen transition="dialog-top-transition">

        <v-card>
          <v-toolbar dark color="black">






            <!--
          <v-btn
            icon
            dark
            @click="dialogLanding = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
       
          <v-toolbar-title>Connect Tezos Wallet</v-toolbar-title>
           -->
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <!-- 

        <v-btn  @click="connectWallet()"><v-icon left>mdi-login-variant</v-icon> Connect Wallet</v-btn>

       
            <v-btn
              variant="text"
              @click="dialogLanding = false"
            >
              Connect Wallet
            </v-btn>
            -->
            </v-toolbar-items>
          </v-toolbar>

          <div>
            <v-img class="mx-auto my-6" max-width="228"
              src="https://cdn.vuetifyjs.com/docs/images/logos/vuetify-logo-v3-slim-text-light.svg"></v-img>

            <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">

              <v-card class="mb-12" color="surface-variant" variant="tonal">

                <v-card-text class="text-medium-emphasis text-caption">
                  Welcome to DIYFRAME. Connect your wallet to get started. </v-card-text>
              </v-card>


              <v-btn block class="mb-8" color="blue" size="large" variant="tonal" @click="connectWalletBridge">
                Connect Wallet
              </v-btn>

            </v-card>
          </div>



        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="dialogDonation" persistent scrollable max-width="500" min-width="330" transition="dialog-top-transition">


      <v-card flat class="rounded-lg">
      <v-toolbar
          dark
          color="grey"
        >
          <v-btn
            icon
            dark
            @click="dialogDonation = false"
            to="/"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>DONATE</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>

        <v-card-text class="text-center align-center justify-center">

  <v-avatar
        color="grey"
        size="100"
        rounded="lg"
      >
        <v-img cover src="https://andrefuchs.xyz/avatar.png"></v-img>
      </v-avatar>
    </v-card-text>

      <v-card-text v-if="this.operationHash === 'success'">
        Your generous donation is truly appreciated!<br/>
        Thank you.<br/>
        🧡 🦊️
        </v-card-text>

        <v-card-text  v-else-if="this.operationHash === 'error'">
          An error happened. You can also send your donation directly to andrefuchs.tez.<br/>
          Thank you!<br/> 
          🧡 🦊️
        </v-card-text>

        <v-card-text>
          Please consider making a donation to support Andre Fuchs, the dedicated sole developer behind diyFrame.<br/><br/>
          You can choose an amount from the options below or send your donation directly to andrefuchs.tez.
          <!-- 
            learn more: history
         
          -->
        </v-card-text>


    <v-divider class="mx-4"></v-divider>

    <v-card-text>
      <span class="subheading">Select an amount</span>

      <v-chip-group
        v-model="selectionDonate"
        selected-class="text-red"
        mandatory
      >
      <v-chip
      v-for="amount in amounts"
      :key="amount"
      size="x-large"
      :value="amount"
    >
      {{ amount }} tez
    </v-chip>
      </v-chip-group>
    </v-card-text>

   
 

      <!-- 
        STORY
        2 years in the making 
      one person
      x thousand lines of code
      
      -->


        <v-card-actions>
          <v-btn color="#FF5400" variant="flat" block @click="makeDonation()">Donate {{ selectionDonate }} tez Now</v-btn>
        </v-card-actions>
      </v-card>

    
    </v-dialog>


<!-- CLOSE btn adden -->
    <v-dialog v-model="dialogAddToFrame" scrollable max-width="500" min-width="330" >
      <v-card flat class="rounded-lg">
      <v-toolbar
          dark
          color="grey"
        >
          <v-btn
            icon
            dark
            @click="dialogAddToFrame = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="d-inline-block text-truncate" style="max-width: 300px;">ADD: “{{ this.addToFrameTemp.name }}”</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>

     

          <!-- 
        <v-card-text>

          By: {{   this.addToFrameTemp.artist_address  }}
    {{  this.addToFrameTemp.token_id  }} {{  this.addToFrameTemp.fa2_address  }} 
        </v-card-text>
-->
        <v-list-item>
            <v-btn variant="outlined" class="mt-4" block @click="handleFormSubmitNewFrameWithEntry(this.addToFrameTemp)" depressed color="#FF5400">
              ADD TO A NEW FRAME
            </v-btn>

          </v-list-item>

        <v-card-text>
          or select a frame:
        </v-card-text>

      

            <v-card-text style="height: 300px;">
              <!-- Add to frame(s) todo get list of frames -->
              <v-radio-group v-model="dialogRadiosAddFrame" column>

                <div v-for="(item, index) in getActiveFrame" :key="index">
                  <v-radio  color="#FF5400" @click="this.addItemIDTemp = '', this.addItemIDTemp = item.id" :label="'Frame ' + item.id" :value="item.id"></v-radio>
                </div>

                <!-- TODO erster frame als default value setzen wenn user direkt save clicked -->
              </v-radio-group>

            </v-card-text>
          


        <v-card-actions>
          <v-btn color="#FF5400" variant="flat" block @click="dialogAddToFrame = false, loadFrame(true, this.addItemIDTemp)">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


<!-- #im #di -->
    <v-dialog v-model="dialogImport" scrollable max-width="500" min-width="330" >
      <v-card flat class="rounded-lg">
      <v-toolbar
          dark
          color="grey"
        >
          <v-btn
            icon
            dark
            @click="goToFirstFrame(), dialogImport = false"
      
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="d-inline-block text-truncate" style="max-width: 300px;">Import: “{{  this.addToFrameTemp.name }}”</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>

     

          <!-- 
        <v-card-text>

          By: {{   this.addToFrameTemp.artist_address  }}
    {{  this.addToFrameTemp.token_id  }} {{  this.addToFrameTemp.fa2_address  }} 
        </v-card-text>
-->

<v-img :src="this.IPFStoHTTPbutiq(this.addToFrameTemp)" class="align-end" gradient="to top, rgba(0,0,0,.1), rgba(0,0,0,.8)"
          height="125px" cover>
          
         <!--  <v-card-title class="text-white text-h1">Title: {{ this.titleShared }}</v-card-title>
        
           {{ this.addToFrameTemp.artist_address }}
          {{ this.addToFrameTemp.fa2_address }}
        -->
         <v-card-title>
            Artist:   {{ this.addToFrameTemp.name }}<br/>
            Token ID: {{ this.addToFrameTemp.token_id }}
           
          </v-card-title>
        </v-img>

        
        <v-list-item>
            <v-btn variant="outlined" class="mt-4" block @click="handleFormSubmitNewFrameWithEntry(this.addToFrameTemp)" depressed color="#FF5400">
              ADD TO A NEW FRAME
            </v-btn>

          </v-list-item>




        <v-card-text>
          or select a frame:

       
         
      
        </v-card-text>

      

            <v-card-text style="height: 300px;">
              <!-- Add to frame(s) todo get list of frames -->
              <v-radio-group v-model="dialogRadiosAddFrame" column>

                <div v-for="(item, index) in getActiveFrame" :key="index">
                  <v-radio  color="#FF5400" @click="this.addItemIDTemp = '', this.addItemIDTemp = item.id" :label="'Frame ' + item.id" :value="item.id"></v-radio>
                </div>

                <!-- TODO erster frame als default value setzen wenn user direkt save clicked -->
              </v-radio-group>

            </v-card-text>
          
<!-- this.addToFrameTemp = item.selectable.token -->

        <v-card-actions>
          <v-btn color="#FF5400" variant="flat" block  @click="goToFirstFrame(), dialogAddToFrame = false, loadFrame(true, this.addItemIDTemp)">SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--
  <v-layout style="height: 128px;" class="border rounded">
    

    <v-bottom-navigation
      :active="activeBottomNav"
      color="#FF5400"
    >

    <v-btn to="/">
        <v-icon>mdi-home</v-icon>

        Home
      </v-btn>

      <v-btn @click="goToFirstFrame()">
        <v-icon>mdi-wallpaper</v-icon>

        Frames
      </v-btn>

      <v-btn  to="/collection/objkt">
        <v-icon>mdi-view-dashboard-outline</v-icon>

        Collection      </v-btn>

      
    </v-bottom-navigation>

  </v-layout>
-->


</template>
  </v-app>
</template>

<!-- TODO 

  Nach sync
this.doFetchTZprofile("tz1NQLhEcgctcNUMmj46qRnWSrcs9iBREgJP")

-->
<script>
import { mapActions, mapGetters } from "vuex";
import { formatPkhString } from "./utils";
import { VDataTableServer } from 'vuetify/lib/labs/components.mjs'
import LandingPage from "./components/LandingPage.vue";

// UMBAUEN nicht alle , $platform: String!
const queryAllTokensByCollector = `query TokensByCollector($address: String!, $platform: String!,  $showAmount: Int ) {
   
   holdings(where: {holder_address: {_eq: $address}, amount: {_gt: "0"}, token: {platform: {_eq: $platform}}}, order_by: {last_received_at: desc}, limit: $showAmount) {
    token {
     platform
     fa2_address
      objkt_artist_collection_id
     token_id
        name
   description
   updated_at
   thumbnail_uri
   mime_type
       artist_address
   artist_profile {
       alias
     }
   }
 
}
}`;

// #q
const queryTZprofile = `query GetTzProfileByAccount($valAccount: String = "") {
  tzprofiles(where: {account:{_eq: $valAccount}}) {
   account
   alias
  }
}
`;

// /add?token_id=373726&fa2_address=KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton
// SHARE API
const querySingleToken = `query GetSingleToken($fa2Address: String = "", $tokenId: String = "") {
    tokens_by_pk(fa2_address: $fa2Address, token_id: $tokenId) {
        name
    artist_profile {
        alias
      }
      artist_address
       objkt_artist_collection_id
      
       fa2_address
      token_id
      platform
       artifact_uri
    display_uri
    thumbnail_uri
    mime_type
    }  
}
`;

export default {
  name: "App",
  components: {
    VDataTableServer,
    LandingPage
  },

  // #d
  data: () => ({
    ghostnet: true,
    drawerMainNav: true,
    activeUserAvatar: "",
    activeUserName: "", //(No Wallet Connected)
    activeBottomNav: true,
    amounts: [5, 10, 50, 100],
    selectionDonate: 10,
    serverItemsFrameUpdate: [],
    addToFrameTemp: "",
    addItemIDTemp: "",
    rail: true,
    dialogAddToFrame: false, // TODO: to false
    operationHash: null,
    drawerAd: true,
    // openSubMenu: ['Frames','Collection'],
    openSubMenu: [],
    itemsLanding: [
     // { text: 'Tutorials', icon: 'mdi-help-circle-outline', to: 'https://blog.diyframe.xyz/tag/tutorial/' },
     // { text: 'FAQ', icon: 'mdi-help-circle-outline', to: 'https://blog.diyframe.xyz/tag/faq/' },
     // { text: 'News', icon: 'mdi-decagram-outline', to: 'https://blog.diyframe.xyz/tag/news/' },
      { text: 'Blog', icon: 'mdi-newspaper-variant-outline', to: 'https://blog.diyframe.xyz/' },
      { text: 'Twitter', icon: 'mdi-twitter', to: 'https://twitter.com/diyframe_xyz' },
      { text: 'Instagram', icon: 'mdi-instagram', to: 'https://instagram.com/diyframe.xyz' },
      { text: 'LinkedIn', icon: 'mdi-linkedin', to: 'https://www.linkedin.com/company/diyframe' },
    ],
    itemsCollection: [
      // { text: 'Re—tain', icon: 'mdi-chevron-right', to: '/collection/re-tain', platform: 'RETAIN' },
      { text: 'fx(hash)', icon: 'mdi-chevron-right', to: '/collection/fxhash', platform: 'FXHASH' },
      { text: 'Objkt', icon: 'mdi-chevron-right', to: '/collection/objkt', platform: 'OBJKT' },
      { text: 'Teia', icon: 'mdi-chevron-right', to: '/collection/teia', platform: 'HEN' },
      { text: 'Versum', icon: 'mdi-chevron-right', to: '/collection/versum', platform: 'VERSUM' },
    ],
   
    dialogLanding: false, //TODO auf true
    dialogRadios: '',
    dialogRadiosAddFrame: '',
    dialogImport: false,
    dialogDonation: false,
    headersFrame: [

      {
        title: 'Thumb',
        value: 'token.thumbnail_uri',
        align: 'start',
        sortable: false,
        key: 'thumb',
      },
     
      {
        title: 'Title',
        value: 'token.name',
        align: 'start',
        sortable: false,
        key: 'name',
      },
      { title: 'Artist', value: 'token.artist_address', key: 'artist', align: 'start', sortable: false },
      { title: 'Platform', value: 'token.platform', key: 'platform', align: 'start', sortable: false },
      { title: 'Delete', key: 'action', align: 'start', sortable: false },
    ],
    headersCollection: [

      {
        title: 'Thumb',
        value: 'token.thumbnail_uri',
        align: 'start',
        sortable: false,
        key: 'thumb',
      },
    
      {
        title: 'Title',
        value: 'token.name',
        align: 'start',
        sortable: false,
        key: 'name',
      },
      { title: 'Add', key: 'action', align: 'start', sortable: false },
      { title: 'Artist', value: 'token.artist_address', key: 'artist', align: 'start', sortable: false },
      { title: 'Platform', value: 'token.platform', key: 'platform', align: 'start', sortable: false },

     

    ],
    itemsPerPageCollection: 10,
    itemsPerPageFrame: 10,
    searchCollection: '',
    searchFrame: '',
    serverItemsCollection: [],
    serverItemsFrame: [],
    loadingCollection: false, //TODO auf true setzen
    loadingFrame: false, //TODO auf true setzen
    totalItemsCollection: 0,
    totalItemsFrame: 0,
    loadingWriteToContract: false,
  }),

  // #m
  mounted() {
    // console.log("✅ mounted", this.$route);  
console.log("diyFrame by Andre Fuchs");
console.log("2023, https://andrefuchs.xyz");
console.log("🦊️");
console.log("— — — — — — — —");

// ROUTE CHANGE
    this.$watch(() => this.$route.path, (to, from) => {
      console.log('route path has changed from ' + from + ' to ' + to)
      //    this.itemsPerPage = 10

      window.scrollTo(0, 0)

      // HACK: LOAD NEW MARKETPLACE COLLECTION
      if (this.$route.path.indexOf('/collection/') !== -1) {
      this.searchCollection = String(Math.random())
      }

      else if (this.$route.path.indexOf('/add') !== -1) {
      this.getShareParams()
    }

    else if (this.$route.path.indexOf('/donate') !== -1) {
    this.dialogDonation = true
    }

   //  else if (this.$route.path.indexOf('?wallet_address=') !== -1) {
     // this.getShareParams() pkh overwrite?
     // this.pkh overwrite wie? add new section?
   //  }

// HACK: LOAD NEW FRAME CONTENT
     else  if (this.$route.path.indexOf('/frame/') !== -1) {
   // this.loadFrame(false, this.$route.params.id)
     this.searchFrame = String(Math.random())
      }
      //   this.loadCollection()

    })
    // IF connected dialogLanding = false


    //     // Code that will run only after the
    // 

    // MOUNTED (entire view has been rendered)
    this.$nextTick(function () {
      console.log("✅ mounted $nextTick");
      // console.log("✅ this.$route", this.$route.params);

if(this.isMobile) {
this.drawerMainNav = false
this.drawerAd = false
}

      console.log("this.getPkh ", this.getPkh);
      //  this.loadFrame()
      const checkLogin = this.getPkh
      if (checkLogin === "" || checkLogin === undefined || checkLogin === null) {

        if (this.$route.path.indexOf('/frame/') !== -1) {
      // this.loadFrame(false, this.$route.params.id) FALSCH? in else? 
      this.searchFrame = String(Math.random())
      }

        // TODO WIEDER FREISCHALTEN this.dialogLanding = true
      } else {
        // NICHT LOGIN
        this.dialogLanding = false
       //  this.doFetchTZprofile("tz1NQLhEcgctcNUMmj46qRnWSrcs9iBREgJP")
        //  this.getActiveFrameConvert()
        // this.$router.push('/frames')
      }

    })

    // DEV: this.disconnectWallet()

    // EDIT ART TEST: http://localhost:8080/add?token_id=98&fa2_address=KT1BWmKdq42DWgwCTesG3t8hXJSx4cFDt46u
    // https://objkt.com/asset/KT1BWmKdq42DWgwCTesG3t8hXJSx4cFDt46u/98

    // GEHT NUR ÜBER RELOAD HIER?! 



  },
  computed: {
    ...mapGetters(["getConnected", "getPkh", "getActiveFrame", "getLoading"]),

    isMobile() {
      return window.innerWidth <= 400; 
    }
  },

  watch: {
    getConnected(newValue) {
      if (newValue) {
        this.getFrameList();
      }
    }
  },
  created() {
    this.checkWalletConnection();
  },
  // #me
  methods: {
    ...mapActions(["donateNow", "addFrame", "checkWalletConnection", "getFrameList", "disconnectWallet", "updateFrame", "deleteFrame", "connectWallet"]),

    getPkhBridge() {
if(this.ghostnet){
  console.log("gfhost");
  return "tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v"
} else {
  return this.getPkh()
}
    },
    updateToolbarTitle(){
     // if(this)
     return "diyFrame"
    },
    goToFirstFrame() {
    const frames = this.getActiveFrame
console.log("frames ",frames);

    if(frames.length > 0) {
      this.$router.push('/frame/'+frames[0].id)
    } else {
      this.$router.push('/frame')
    }
    },
   
    openFrameURL(valFrameID){
      
if(process.env.NODE_ENV === "development") {
  return "https://play.diyframe.xyz/"+valFrameID
} else {
 return "https://play.diyframe.xyz/"+valFrameID
}
      },
    toggleFullScreen(event) {
 var element = document.body;

	if (event instanceof HTMLElement) {
		element = event;
	}

	var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

	element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
	document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

	isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
      // document.documentElement.requestFullscreen({ navigationUI: 'hide' })
    },

    // -----------------------------
    // NEHMEN FÜR ADD FRAME ENTRY?
    async deleteFrameEntry(val, valID) {

      // this.loading = true
      console.log(">deleteFrameEntry >BEFORE: ", this.serverItemsFrame);
      console.log(">deleteFrameEntry >val ", val);

let entryFound = null;


for (let i = 0; i < this.serverItemsFrame.length; i++) {
  if (this.serverItemsFrame[i].artifact_uri === val) {
    entryFound = this.serverItemsFrame[i];
    break;
  }
}

if (entryFound !== null) {
  console.log("Found object:", entryFound);

  const indexToDelete = this.serverItemsFrame.findIndex(obj => obj.artifact_uri === val);
  this.serverItemsFrame.splice(indexToDelete, 1);

  console.log(">deleteFrameEntry >AFTER: ", this.serverItemsFrame);


  // SAME WIE HANDLE UPDATE
  let newServerItemsFrame   =  []

for (let i = 0; i < this.serverItemsFrame.length; i++) {
 newServerItemsFrame += this.serverItemsFrame[i].token_id +","+ this.serverItemsFrame[i].fa2_address +","
} 

  // if nur 1 entry der auch noch gelöscht wurde:
if(this.serverItemsFrame.length === 0) {
  this.deleteFrameID(valID)

} else {
 await this.updateFrame({ frame: newServerItemsFrame, id: valID });  
}

} else {
  // If no matching object was found
  console.log("Object not found.");
}      
    },
    formatTez(val) {
      if(val != null) {
      return val.substring(0, 5) + "..." + val.substring(val.length - 4);
    }
    },

    // DOPPELT DRIN?
    async getAllTokensByPlatform(address, platform) {

      const { errors, data } = await this.fetchGraphQL(queryAllTokensByCollector, "TokensByCollector", { "address": address, "platform": platform, "showAmount": this.itemsPerPageCollection  });

      if (errors) {
        console.error(errors);

      } else {
 
        return data
      }
    },
    async connectWalletBridge() {
      const result = await this.connectWallet();
      if (result === "success") {
        this.dialogLanding = false 
        this.$router.push('/#')
      }
    },
    async makeDonation() {

console.log("selectionDonate ", this.selectionDonate);
     // const payload = 'Your argument here';
     const result = await this.$store.dispatch('donateNow', this.selectionDonate);



     // const result = await this.donateNow("5");
     console.log("result ",result)
this.operationHash = result; 
  
    },
    // DELETE COMPLETE FRAME WITH EVERYTHING IN IT
    async deleteFrameID(id) {
      console.log(">deleteFrameID >id ", id);
      // this.loading = true;
      await this.deleteFrame(id);
      // this.loading = false;
    },

    // CREATE NEW FRAME WITH AN ENTRY
    async handleFormSubmitNewFrameWithEntry(val) {
      // this.loading = true;
      let payload = val;

      // fehlt da hinter komma? ode vorner besser gesag?!
      payload = this.addToFrameTemp.token_id + "," +  this.addToFrameTemp.fa2_address

      // TODO filter to format wie break teil
      console.log("handleFormSubmitNewFrameWithEntry: ", val, payload);
      const result = await this.addFrame(payload);

      if(result === "success") {
        this.dialogAddToFrame = false
        this.dialogImport = false

        // get current url ->lassen wo ist oder sonst startseite?! oder der neue frame der erstellt wurde
      } else {
        this.dialogAddToFrame = false
        this.dialogImport = false
      }
   // DIRTY

   // TODO ADD toast "added"
      // this.loading = false;
    },
   
    // TODO VIA tzProfiles API
    async doFetchTZprofile(valAccount) {
      const { errors, data } = await this.fetchGraphQL(queryTZprofile, "GetTzProfileByAccount", { "valAccount": valAccount });

      console.log("doFetchTZprofile data: ", data);
      if (errors) {
        console.error(errors);
      }
    },

    IPFStoHTTPbutiq(val) {
      val = this.pickTokenDisplayUri(val)
      val = val.uri

      if (val) {

        return val.split('ipfs://').join('https://ipfs.io/ipfs/')
        // return this.ipfsResolver(val) // 
      }
      // return '@/assets/logo.png'
    },

    pickTokenDisplayUri(token) {
      if (
        token.platform === 'FXHASH' &&
        token.thumbnail_uri &&
        token.thumbnail_uri === 'ipfs://QmbvEAn7FLMeYBDroYwBP8qWc3d3VVWbk19tTB83LCMB5S' &&
        token.fx_collection_display_uri
      ) {
        return {
          uri: token.fx_collection_display_uri,
        };
      }

      if (!token.display_uri && token.thumbnail_uri === 'ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc') {
        // HEN, early mints
        return {
          uri: token.artifact_uri,
          is_video: token.mime_type === 'video/mp4',
        };
      }

      if (
        token.platform === 'VERSUM' &&
        (token.mime_type === 'image/gif' ||
          token.mime_type === 'image/jpeg' ||
          token.mime_type === 'image/png' ||
          token.mime_type === 'image/jpg')
      ) {
        return {
          uri: token.artifact_uri,
        };
      }

      if (token.platform === 'VERSUM' && parseInt(token.token_id, 10) <= 27951 && token.mime_type === 'video/mp4' && token.formats !== undefined) {
        // the video was used in the display_uri in the early versum days. pick first jpg in formats instead.
        try {
          const jpgFormat = token.formats.find((format) => format.mime_type === 'image/jpeg');

          if (jpgFormat) {
            return {
              uri: jpgFormat.uri,
            };
          }
        } catch (err) {
          console.log("ERROR: ", err);
        }
      }

      return {
        uri: token.display_uri || token.thumbnail_uri,
      };
    },
    async fetchGraphQL(operationsDoc, operationName, variables) {
      const result = await fetch(
        "https://api.teztok.com/v1/graphql",
        {
          method: "POST",
          body: JSON.stringify({
            query: operationsDoc,
            variables: variables,
            operationName: operationName
          })
        }
      );
      return await result.json();
    },

   async loadFrame(valUpdate, valID) {
      //  loading = true 
      

      // pageFrame, 
      // { itemsPerPageFrame, sortByFrame, searchFrame }
      console.log("------------------loadFrame-------------------------------")

   this.serverItemsFrame.length = 0 // = []
      const frames = this.getActiveFrame

if(frames === undefined) {
  return
}

      let getIndex = 0

      if (frames.length > 0) {

        for (let i = 0; i < frames.length; i++) {

          if (frames[i].id === valID) {
           getIndex  = i;
    break;
  }
        }

        var valuePairs = frames[getIndex]["frame"]
        const values = valuePairs.split(',');
const pairs = [];
for (let i = 0; i < values.length; i += 2) {
  if (i + 1 < values.length) {
    pairs.push([values[i], values[i + 1]]);
  }
}

          for (let i = 0; i < pairs.length; i++) {
await  this.fetchSingleToken(pairs[i][0], pairs[i][1])
// this.loading = false;
          }
        } else {
          console.log("No content found between brackets.");
        }

// IF UPDATE
if(valUpdate) {

  // this.loading = true;
  console.log("##### valUpdate ",valUpdate);

let newadded = this.addToFrameTemp.token_id + "," +  this.addToFrameTemp.fa2_address
let xxnewServerItemsFrame = ""

console.log("##### ",this.serverItemsFrame.length);

for (let i = 0; i < this.serverItemsFrame.length; i++) {
// TODO in frame display >!NaN !undefined
 xxnewServerItemsFrame +=  this.serverItemsFrame[i].token_id + "," + this.serverItemsFrame[i].fa2_address  +  ","
    }
   // needs return?
const result = await this.updateFrame({ frame: xxnewServerItemsFrame + newadded +  ",", id: valID }); 

if(result === "success") {
        this.dialogAddToFrame = false
        this.dialogImport = false
// func machen
        // get current url ->lassen wo ist oder sonst startseite?! oder der neue frame der erstellt wurde
      } else {
        this.dialogAddToFrame = false
        this.dialogImport = false
      }
// this.loading = false;
}
    },
    
    async loadCollection({ page, itemsPerPage, sortBy, search }) {
      //  loading = true

      console.log("------------------loadCollection------------------------------- ")
      //  console.log(">>>>>>>>>>>>>>>>> ", this.$route.path)
      console.log("SEARCH name: ", search)
      console.log("page: ", page)
      console.log("sortBy: ", sortBy)
      console.log("sortBy: ", itemsPerPage)

      let platformFilter = "'FXHASH', 'OBJKT', 'HEN', 'VERSUM'"

      /*
      // TODO ADD AFTER R-T LAUNCH:
       if (this.$route.path.indexOf('/collection/re-tain') !== -1) {
         platformFilter = "RE-TAIN"
       } else if 
     */ 
      if (this.$route.path.indexOf('/collection/fxhash') !== -1) {
        platformFilter = "FXHASH"
      } else if (this.$route.path.indexOf('/collection/objkt') !== -1) {
        platformFilter = "OBJKT"
      } else if (this.$route.path.indexOf('/collection/teia') !== -1) {
        platformFilter = "HEN"
      } else if (this.$route.path.indexOf('/collection/versum') !== -1) {
        platformFilter = "VERSUM"
      }

      const { errors, data } = await this.fetchGraphQL(queryAllTokensByCollector, "TokensByCollector", { "address": this.getPkhBridge(), "platform": platformFilter, "showAmount": this.itemsPerPageCollection });
      
// const { errors, data } = await this.fetchGraphQL(platformFilter, "GetFullCollection", { "fa2Address": "tz1WNeY9qV5bppwYk5NVtRonRRU1whPH8wmr", "showAmount": itemsPerPage });

      if (errors) {
        console.error(errors);
      } else {
 
        this.serverItemsCollection = data.holdings

      }

    },

    getUsername(valAlias, valTezosAddress) {
      // TODO collab
      // console.log("valAlias ", valAlias, valTezosAddress);
      if (valAlias == "" || valAlias === null || valAlias === undefined) {
        return formatPkhString(valTezosAddress)
      } else {
        // console.log("valAlias length", valAlias );
        return valAlias
      }
    },

    // TODO test für: objkt fxhash teia versum 
    // add?token_id=373726&fa2_address=KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton
    // add?token_id=831114&fa2_address=KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton
    // LEWIS: 831114 KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton
   
    async fetchSingleToken(token, address) {

      const { errors, data } = await this.fetchGraphQL(querySingleToken, "GetSingleToken", { "fa2Address": address, "tokenId": token });

      if (errors) {
        console.error(errors);
      } else {     
         this.serverItemsFrame.push(data.tokens_by_pk)
              }
    },
    // #gsp
    async getShareParams() {

      const { errors, data } = await this.fetchGraphQL(querySingleToken, "GetSingleToken", { "fa2Address": this.$route.query.fa2_address, "tokenId": this.$route.query.token_id });

      if (errors) {
        console.error(errors);

      } else {
        this.dialogImport = true
        // GEHT:
        // dialog = true
        // wie clean übergeben?

        // ENDE CHECLK:  TODO reset by close nach added
        if (this.$route.query.token_id !== undefined && this.$route.query.fa2_address !== undefined) {
        
          this.addToFrameTemp = data.tokens_by_pk

          /*
          if (data.tokens_by_pk.artist_profile === null) {
            this.artistShared = "/"
          } else {
            this.artistShared = this.getUsername(data.tokens_by_pk.artist_profile.alias, data.tokens_by_pk.artist_address)
          }
          */
        }
        // 
        console.log("getShareParams(): ", data.tokens_by_pk)
      }
    },
  },
};
</script>
