<template>
 <v-card>
          <v-toolbar dark color="black">






            <!--
          <v-btn
            icon
            dark
            @click="dialogLanding = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
       
          <v-toolbar-title>Connect Tezos Wallet</v-toolbar-title>
           -->
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <!-- 

        <v-btn  @click="connectWallet()"><v-icon left>mdi-login-variant</v-icon> Connect Wallet</v-btn>

       
            <v-btn
              variant="text"
              @click="dialogLanding = false"
            >
              Connect Wallet
            </v-btn>
            -->
            </v-toolbar-items>
          </v-toolbar>

          <div>
           <!--  
            <v-img class="mx-auto my-6" max-width="228"
              src="https://cdn.vuetifyjs.com/docs/images/logos/vuetify-logo-v3-slim-text-light.svg"></v-img>
-->
            <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">

              <v-card class="mb-12" color="surface-variant" variant="tonal">

                <v-card-text class="text-medium-emphasis text-caption">
                  Welcome to DIYFRAME. Connect your wallet to get started. </v-card-text>
              </v-card>


              <v-btn block class="mb-8" color="blue" size="large" variant="tonal" @click="connectWallet">
                Connect Wallet
              </v-btn>

            </v-card>
          </div>



        </v-card>
</template>


<script>
import { mapActions } from "vuex";

  export default {
    data: () => ({
      test: ""
    }),
    methods: {
    ...mapActions(["connectWallet"]),
    } }
</script>